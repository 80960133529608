import { useGetWorksheetSetAssigment } from "@/lib/apis/v2/queries/getWorksheetSetAssigment";
import { createFileRoute } from "@tanstack/react-router";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { WorksheetSetAssignmentWorksheetSetVersionRelation } from "@/graphql/generated/graphql";
import { Badge } from "@/components/ui/badge";
import { AddWorksheetSetAssignmentModal } from "@/components/add-worksheet-set-assigment-modal";

interface FlattenedData {
  garageUserId: number;
  garageClassroomSessionId: number;
  worksheetSetVersion: WorksheetSetAssignmentWorksheetSetVersionRelation[];
}

// Helper function to group data by garageUserId and garageClassroomSessionId
const groupBy = <T,>(
  array: T[],
  keyFn: (item: T) => string
): Record<string, T[]> => {
  return array.reduce(
    (result, item) => {
      const key = keyFn(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    },
    {} as Record<string, T[]>
  );
};

export const Route = createFileRoute("/_auth/operations/assignments/")({
  component: () => <OperationAssigmentsPage />,
});

function OperationAssigmentsPage() {
  const { data } = useGetWorksheetSetAssigment({ where: {} });

  // Type guard to ensure data is available
  const assignments = data?.worksheetSetAssignment ?? [];

  // Group data by garageUserId and garageClassroomSessionId
  const groupedData = groupBy(
    assignments,
    (item) => `${item.garageUserId}-${item.garageClassroomSessionId}`
  );

  // Flatten the grouped data to match the table format
  const flattenedData: unknown[] = Object.entries(groupedData).map(
    ({ 1: items }) => {
      const { garageUserId, garageClassroomSessionId } = items[0];
      const worksheetSetVersion = items.map((item) => item.worksheetSetVersion);
      return {
        garageUserId,
        garageClassroomSessionId,
        worksheetSetVersion,
      };
    }
  );

  // Define table columns
  const columns = [
    {
      accessorKey: "garageUserId",
      header: "Garage User ID",
    },
    {
      accessorKey: "garageClassroomSessionId",
      header: "Classroom Session ID",
    },
    {
      accessorKey: "worksheetSetVersion",
      header: "Worksheet Set Versions",
      cell: (props: CellContext<FlattenedData, unknown>) => (
        <ul>
          {props.row.original.worksheetSetVersion.map(
            (version: WorksheetSetAssignmentWorksheetSetVersionRelation) => (
              <li key={version.id}>
                <Badge variant="outline" className="mr-1 mb-1">
                  {version?.worksheetSet?.name} (Version {version.version})
                </Badge>
              </li>
            )
          )}
        </ul>
      ),
    },
  ];

  // Set up the React Table instance
  const table = useReactTable({
    data: flattenedData as FlattenedData[],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="grid gap-4 m-6">
      <div className="flex justify-between">
        <h2 className="font-bold">Worksheet Set Assigments</h2>
        <AddWorksheetSetAssignmentModal />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default OperationAssigmentsPage;
