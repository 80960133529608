import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { PlusCircleIcon } from "lucide-react";
import StudentListCombobox from "./student-list-combobox";
import WorksheetSetVersionListCombobox from "./worksheet-set-version-list-combobox";
import { useInsertWorksheetSetAssignment } from "@/lib/apis/v2/mutations/insertWorksheetSetAssigment";
import { toast } from "sonner";
import { Input } from "./ui/input";

export function AddWorksheetSetAssignmentModal() {
  const [userId, setUserId] = useState<number | null>(null);
  const [worksheetSetVersionId, setWorksheetSetVersionId] = useState<
    number | null
  >(null);
  const [sessionId, setSessionId] = useState<number | null>(null);

  const mutation = useInsertWorksheetSetAssignment();

  const handleSave = () => {
    if (!userId || !worksheetSetVersionId) {
      return;
    }
    try {
      mutation.mutate({
        values: {
          garageClassroomSessionId: Number(sessionId),
          garageUserId: Number(userId),
          worksheetSetVersionId: Number(worksheetSetVersionId),
          isMarkingComplete: false,
        },
      });
      toast.success("Saved!");
      window.location.reload();
    } catch (error) {
      toast.error("Error saving worksheet set assignment");
    }
  };

  return (
    <Dialog>
      <div className="w-full flex justify-end">
        <DialogTrigger asChild>
          <Button variant="secondary" size="sm">
            <PlusCircleIcon className="mr-2 h-4 w-4" /> Add Worksheet Set
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Assign Worksheet Set</DialogTitle>
          <DialogDescription>
            Assign a new Worksheet Set to a student
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Session ID 
            </Label>
            <Input
              className="w-full col-span-3"
              type="number"
              placeholder="Garage Session ID"
              onChange={(e) => setSessionId(Number(e.currentTarget.value))}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Student
            </Label>
            <div className="w-full col-span-3">
              <StudentListCombobox onSelect={(id) => setUserId(id)} />
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Worksheet Set
            </Label>
            <WorksheetSetVersionListCombobox
              onSelect={(id) => setWorksheetSetVersionId(id)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              disabled={!userId || !worksheetSetVersionId || !sessionId}
              onClick={handleSave}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
