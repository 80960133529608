import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { CommandLoading } from "cmdk";
import { useGetWorksheetSetVersion } from "@/lib/apis/v2/queries/getWorksheetSetVersion";

interface WorksheetSetVersionListComboboxProps {
  onSelect: (id: number) => void;
}

const WorksheetSetVersionListCombobox = ({
  onSelect,
}: WorksheetSetVersionListComboboxProps) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null | {
    id: number;
    name: string;
  }>(null);
  const [searchText, setSearchText] = useState("");
  const { data, isPending, error } = useGetWorksheetSetVersion({ where: {} });

  const filteredData = data?.worksheetSetVersion?.filter((item) => {
    const combinedWithVersion = `${item?.worksheetSet?.name} - Version ${item?.version}`;
    return combinedWithVersion
      ?.toLowerCase()
      .includes(searchText.toLowerCase());
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedItem ? selectedItem?.name : "Select Worksheet..."}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput
            placeholder="Search framework..."
            className="h-9"
            onChangeCapture={(e) => {
              setSearchText(e.currentTarget.value);
            }}
          />
          <CommandList>
            {error && (
              <CommandEmpty>Error while fetching student data</CommandEmpty>
            )}
            {isPending && (
              <CommandLoading>
                <div className="flex p-2 items-center text-sm">
                  Fetching data...
                </div>
              </CommandLoading>
            )}
            {!isPending && filteredData?.length === 0 && (
              <CommandEmpty>
                <div className="flex p-2 items-center text-sm">
                  No worksheet set found.
                </div>
              </CommandEmpty>
            )}
            {!isPending &&
              !!filteredData?.length &&
              filteredData?.map((item) => (
                <div
                  className="flex items-center p-2 cursor-pointer text-sm hover:bg-gray-100"
                  key={item?.id}
                  onClick={() => {
                    setSelectedItem({
                      id: Number(item?.id),
                      name: `${item?.worksheetSet?.name} - Version ${item?.version}`,
                    });
                    onSelect(Number(item?.id));
                    setOpen(false);
                  }}
                >
                  {`${item?.worksheetSet?.name} - Version ${item?.version}`}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      selectedItem?.id === item?.id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </div>
              ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default WorksheetSetVersionListCombobox;
