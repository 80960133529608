import ErrorHandler from "@/components/error-handler";
import { Spinner } from "@/components/ui/spinner";
import WorksheetSets from "@/components/worksheet-sets";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useGetWorksheetSetAssigment } from "@/lib/apis/v2/queries/getWorksheetSetAssigment";
import StudentList from "@/components/student-list";
import Breadcrumbs from "@/components/breadcrumbs";

export const Route = createFileRoute(
  "/_auth/teacher/session/$sessionId/student/$userId/"
)({
  component: () => <StudentWorksheets />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (context.auth.user?.role === "student") {
      throw redirect({ to: "/student" });
    }
  },
});

function StudentWorksheets() {
  const { sessionId, userId } = Route.useParams();

  const { isPending, error, data } = useGetWorksheetSetAssigment({
    where: {
      garageClassroomSessionId: {
        eq: Number(sessionId),
      },
      garageUserId: {
        eq: Number(userId),
      },
    },
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <div>
            <StudentList
              sessionId={Number(sessionId)}
              activeStudentId={Number(userId)}
            />
          </div>
          <div className="grid gap-4">
            <Breadcrumbs />
            <div className="py-2">
              <h1 className="text-lg font-bold">Worksheet Sets</h1>
              <section className="grid gap-2">
                {!!data.worksheetSetAssignment?.length && (
                  <WorksheetSets
                    data={data}
                    studentId={userId}
                  />
                )}
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
